import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import InputField from '../common/Input';
import Modal from '../common/Modal';
import Select, { components } from 'react-select';
import ls from 'local-storage';
import { MasterService } from '../../services';
import { toast } from 'react-toastify';

const InactivePopUp = (props) => {
  const [search_text, setSearchText] = useState('');
  const [assigned_dealer_data, setAssignedDealerData] = useState([]);
  const [executiveValues, setExecutiveValues] = useState({});
  const [showSelectField, setShowSelectField] = useState(null);

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  useEffect(() => {
    setAssignedDealerData(props.assigned_dealer);
   
  }, [props.assigned_dealer]);

  useEffect(() => {
    if (props.assigned_dealer && props.assigned_dealer.length > 0 && props.userDetail.reporting_response.length) {
      const initialValues = {};
      props.assigned_dealer.forEach((element) => {
        const matchedExecutive = props.executiveData?.find(
          (item) => item.id === props.userDetail.reporting_response[0].manager_id
        );
        initialValues[element.dealer_id] = matchedExecutive
          ? { ...matchedExecutive, is_checked: true }
          : null;
      });
      setExecutiveValues(initialValues);
    }
  }, [props.assigned_dealer, props.executiveData,props.userDetail]);

  const handleInputChange = useCallback(
    async (value) => {
      setSearchText(value);
      if (value.length > 2 || value.length === 0) {
        let params = {
          vertical_id: 1,
          page: 1,
          status: '1',
          per_page: 50,
          search_text: value,
          user_id:props.userDetail.id
        };
        await props.handleAssignedDealer(params, assigned_dealer_data);
      }
    },
    [props.handleUnAssignedDealer, assigned_dealer_data]
  );



  const handleConfirmedSubmit = useCallback(async () => {
    // Group dealers by selected executive
    const groupedUpdates = props.assigned_dealer.reduce((acc, dealer) => {
        const executive = executiveValues[dealer.dealer_id];
        if (executive) {
            if (!acc[executive.user_id]) {
                acc[executive.user_id] = {
                    assigned_user_id: executive.user_id,
                    assigned_user_name: executive.user_name,
                    beforeUser_id: props.userDetail.id,
                    beforeUser_name: props.userDetail.name,
                    is_confirmed: executive.is_checked ? 1 : 0,
                    dealers: []
                }
            }
            acc[executive.user_id].dealers.push({
                dealer_id: dealer.dealer_id,
                dealer_name: dealer.name
            })
        }
        return acc;
    }, {});

    // Convert grouped data to array format
    const updates = Object.values(groupedUpdates);
    
    if (updates.length > 0) {
      console.log(updates);
      await props.updateAssignExecutiveBulk(updates);
    }
    
    setSearchText('');
  }, [props.assigned_dealer, executiveValues, props.userDetail, props.updateAssignExecutiveBulk]);

  const handleCheckboxChange = useCallback((dealerId) => {
    setExecutiveValues((prev) => ({
      ...prev,
      [dealerId]: {
        ...prev[dealerId],
        is_checked: !prev[dealerId]?.is_checked,
      },
    }));
  }, []);

  const sortedAssignedDealerData = useMemo(() => {
    return assigned_dealer_data
      ? [...assigned_dealer_data].sort((a, b) =>
          a.name.localeCompare(b.name, 'en-US', { caseFirst: 'upper' })
        )
      : [];
  }, [assigned_dealer_data]);

  return (
    <div className="inactive-popup">
      <div className='headers'>
      <h3>Partner List</h3>
      <div>

      <h3>Remap to yoddha</h3>
      <h3>Remap open leads?</h3>
      </div>

      </div>
      <div className="sales-mapped-bg">
        <fieldset className="search-bx">
          <div className="material">
            <InputField
              type="text"
              name="search_text"
              id="search_text"
              placeholder="Search Partner"
              value={search_text}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <i className="ic-search"></i>
          </div>
        </fieldset>
        <div className="assigned-partner-list">
          <ul>
            {sortedAssignedDealerData.map((element, index) => (
              <li key={index}>
                <div className="assigned-partner-and-right-arrow">
                  <label>
                    {element.name +
                      (element.dealer_id
                        ? ' (AMBK - ' + element.dealer_id + ')'
                        : '')}
                  </label>
                  <i
                    onClick={() =>
                      setShowSelectField(showSelectField === index ? null : index)
                    }
                    className="ic-arrow_forward listclose-ic"
                  ></i>
                </div>

                <div className="inactive-select-and-checkbox-container">
                  <fieldset className="single-select" style={{ width: '300px' }}>
                    <div className="material">
                      <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        value={executiveValues[element.dealer_id] || null}
                        onChange={(e) =>
                          setExecutiveValues((prev) => ({
                            ...prev,
                            [element.dealer_id]: { ...e, is_checked: prev[element.dealer_id]?.is_checked || false },
                          }))
                        }
                        options={props.executiveData}
                        placeholder="Select Executive"
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </fieldset>
                  <div className="map-all-leads-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        executiveValues[element.dealer_id]?.is_checked || false
                      }
                      onChange={() => handleCheckboxChange(element.dealer_id)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button className="inactive-popup-submit" onClick={handleConfirmedSubmit}>
        Submit
      </button>
    </div>
  );
};
export default InactivePopUp;